$(document).ready(function () {
    const siteLoaderFront = $('#site-loader .site-loader-front');
    const siteLoaderBack = $('#site-loader .site-loader-back');

    siteLoaderFront.removeClass('show');
    siteLoaderBack.removeClass('show');

    $('#main-menu .navbar-nav a.nav-item[href^=\'#\']').on('click', function () {
        siteLoaderFront.addClass('show');
        siteLoaderBack.addClass('show');
    })

    let ignoreOnbeforeunload = false;

    $('a:not(.nav-item)').on('click', function(){
        ignoreOnbeforeunload = true;
    });

    window.onbeforeunload = function() {
        if (ignoreOnbeforeunload){
            return;
        } else {
            siteLoaderFront.addClass('show');
            siteLoaderBack.addClass('show');
        }
        ignoreOnbeforeunload = false;
    };
});
