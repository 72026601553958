$(document).ready(function() {
  const carouselSync = $('.carousel-sync');

  carouselSync.carousel('cycle')
  carouselSync.on('click', '.carousel-control[data-slide]', function (ev) {
    ev.preventDefault();
    $('.carousel-sync').carousel($(this).data('slide'));
  });
  carouselSync.on('mouseover', function(ev) {
    ev.preventDefault();
    $('.carousel-sync').carousel('pause');
  });
  carouselSync.on('mouseleave', function(ev) {
    ev.preventDefault();
    $('.carousel-sync').carousel('cycle');
  });
})
